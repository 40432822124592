import React, { useState, useEffect } from "react"

import facebookIcon from "../../assets/images/about-us/icons/facebook-for-aboutUs-page-sova.ai.svg"
import linkedInIcon from "../../assets/images/about-us/icons/linkedIn-for-aboutUs-page-sova.ai.svg"
import noContentImage from "../../assets/images/common/no-content-image.jpg"
interface Links {
  [k: string]: string
}

const socialLinks: Links = {
  facebookIcon: facebookIcon,
  linkedInIcon: linkedInIcon,
}
interface Props {
  options?: any
  className?: string
  mCustomClass?: string
  children?: React.ReactNode
}

const Slider = ({ options, className, mCustomClass /* children */ }: Props) => {
  const [items] = useState(options)
  const [currentIdx, changeCurrentIdx] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const newIdx = currentIdx === options.length - 1 ? 0 : currentIdx + 1
      return changeCurrentIdx(newIdx)
    }, 50000)
    return () => clearInterval(interval)
  })

  return (
    <div className={className}>
      <div className="sova-card sova-card-slider">
        <div className={`sova-card-content sova-card-content_${mCustomClass ? mCustomClass : ""}`}>
          <img
            src={items[currentIdx].imgSrc || noContentImage}
            alt={items[currentIdx].imgAlt}
            className={`sova-card-image sova-section-img sova-image sova-image-slider_${mCustomClass ? mCustomClass : ""}`}
          />
          {mCustomClass !== "aboutUs-3" && (
            <>
              <p className="sova-card-header sova-card-header_slider sova-common-text-centered sova-p-normal">{items[currentIdx].title}</p>
              <p className="sova-card-description sova-p-tiny">{items[currentIdx].text}</p>
            </>
          )}
          {items[currentIdx].buttonText && <button className="sova-btn sova-btn_secondary sova-btn_small">{items[currentIdx].buttonText}</button>}
          {mCustomClass === "aboutUs-3" && (
            <div className="sova-section-card_body sova-section-card_aboutUs-body sova-section-child sova-section-child_row_aboutUs-3">
              <div className="sova-section-child-card_text-group">
                <span className="sova-section-child-card_heading sova-p-medium ">{items[currentIdx].user}</span>
                <span className="sova-section-child-card_subheading">{items[currentIdx].position}</span>
              </div>
              <div className="sova-section-child-card_social-group">
                {items[currentIdx].links.map(
                  ({ href, socialIcon }: { href: string; socialIcon: string }, key: number) =>
                    href !== "#" && (
                      <a key={key} href={href} target="blank" className={`sova-section-child sova-section_aboutUs-3-socialIconLink sova-section_aboutUs-3-socialIconLink-${key}`}>
                        <img src={socialLinks[socialIcon]} className="sova-section-img sova-section-img_aboutUs-3-social-group" />
                      </a>
                    )
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={`sova-slider-switchers sova-slider-switchers_${mCustomClass ? mCustomClass : ""}`}>
        {items.map((el: any, i: number) => (
          <button className={`sova-slider-btns ${i === currentIdx ? "_active" : ""}`} key={i} onClick={() => changeCurrentIdx(i)} />
        ))}
      </div>
    </div>
  )
}

export default Slider
